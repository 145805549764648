
import "@/assets/sass/template.scss";
import { defineComponent, onMounted, ref } from "vue";
import TableTab from "@/components/kt-datatable/TableTab.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import PaginationUtill from "@/views/util/PaginationUtill";
export default defineComponent({
  name: "assetRegistryElectricity",

  components: { TableTab },
  setup() {
    const table = ref(false);
    const colnamesGas = ref(null);
    let accountId = store.getters.AccountId;
    const tableData = ref([]);
    interface TableHeader {
      name: string;
      key: string;
      sortable: boolean;
    }
    const OriginalTableHeaders = ref<Array<TableHeader>>();
    const accountInfo: any = {
      accountid: accountId,
      stIndx: 1,
      enIndx: 500,
      DomainName: store.getters.DomainName.toLowerCase(),
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Gas", ["Asset Register List"]);
    });

    ApiService.post(
      "api/AssetRegisterSupplier/assetRegisterSupplier",
      accountInfo
    )
      .then(async ({ data }) => {
        OriginalTableHeaders.value = PaginationUtill.getGasColumn.map((a) => ({
          name: a.name,
          key: a.key,
          sortable: true,
        }));
        colnamesGas.value = PaginationUtill.getGasColumn.map((a) => a.key);
        table.value = true;
        tableData.value = data.assetreggas.map((a) => ({
          siteid: a.siteid,
          gasdetailsid: a.gasdetailsid,
          suppliername: a.suppliername,
          siteref: a.siteref,
          billingstreet: a.billingstreet,
          billingcity: a.billingcity,
          billingcountry: a.billingcountry,
          billingpostcode: a.billingpostcode,
          msn: a.msn,
        }));
      })
      .catch((a) => {
        console.log("Api Log Error", a);
      });
    table.value = false;
    const pageheader = "Asset Register List - Gas";
    return {
      pageheader,
      OriginalTableHeaders,
      tableData,
      colnamesGas,
      table,
    };
  },
});
